import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import SocialIcons from '../components/common/SocialIcons'
import Map from '../components/common/map'
import ContactForm from '../components/sections/contact-form'

import '../styles/pages/page-contactus.scss'
import schema from '../utils/schema/general'

const pageContactus = ({ location }) => {
  const data = useStaticQuery(graphql`{
    ghostPage: ghostPage(tags: {elemMatch: {name: {eq: "#page-contactus"}}}) {
      ...GhostPageFields
    }
    hero: file(relativePath: {eq: "hero/hero-contact.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
  }
  `)
  const image = getImage(data.hero)
  const bgImage = convertToBgImage(image)

	const page = data.ghostPage

  return <>
      <MetaData
        data={data}
        location={location}
        type="website"
      />
      <Helmet>
        <style type="text/css">{`${page.codeinjection_styles}`}</style>
        <script type="application/ld+json">{JSON.stringify(schema.localBusiness)}</script>
        <script type="application/ld+json">{JSON.stringify(schema.organization)}</script>
      </Helmet>
      <Layout viewport={'full'}>
        <article className="page-contactus">
          <BackgroundImage
            Tag="section"
            className="contactus__hero"
            {...bgImage}
          >
            <div className="container">
              <SocialIcons type={'light'} position={'relative'} espaWhite={true} />
              <h1 className="visually-hidden">Contact Us</h1>
              <p className="contactus__hero-punchline h1">Are you ready to work with us?</p>
            </div>
          </BackgroundImage>
          <section className="contactus__text">
            <div className="container medium">
              <h2 className="contactus__text-header dec-element--line-vertical">Συμπληρώστε την παρακάτω φόρμα με τα στοιχεία σας <br />και μια σύντομη περιγραφή.</h2>
            </div>
            <div className="container medium">
              <div className="row">
                <div className="col">
                  <h3>Οικονομική Πρόταση</h3>
                  <p>Εμείς σε σύντομο χρονικό διάστημα θα επικοινωνήσουμε μαζί σας και θα σας δώσουμε μία αναλυτική προσφορά που θα καλύπτει πλήρως τις ανάγκες σας και θα προσδιορίζει το κόστος και το χρόνο υλοποίησης του έργου.</p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h3>Τεχνική Υποστήριξη</h3>
                  <p>Σε σύντομο χρονικό διάστημα θα επικοινωνήσουμε μαζί σας για να σας παρέχουμε τις πληροφορίες που χρειάζεστε ή αν πρόκειται για τεχνικό πρόβλημα, θα προβούμε άμεσα στις απαραίτητες ενέργειες για την επίλυσή του.</p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h3>Θέματα Συνεργασίας</h3>
                  <p>Θα επικοινωνήσουμε μαζί σας προκειμένου να έχουμε από εσάς μια πιο πλήρη εικόνα για την πιθανή συνεργασία μας και αν η πρότασή σας παρουσιάζει ενδιαφέρον, θα πραγματοποιηθεί συνάντηση για περαιτέρω συζήτηση.</p>
                </div>
              </div>
            </div>
          </section>
          <section className="contactus__info background__theme--dark">
            <div className="container medium">
              <div className="row">
                <div className="col-sm-5">
                  <p>Main Office</p>
                  <p className="h5">
                    <a href="https://www.google.com/maps/place/Think+Plus+-+Advertising/@38.0381106,23.8048869,17z/data=!3m1!4b1!4m5!3m4!1s0x14a198de25b8ef7b:0xc223731778f7ddf7!8m2!3d38.0381106!4d23.8070756" className="link--plain" title="Find our location" target="_blank">Amarousiou Chalandriou 36B, 15125 Marousi</a>
                  </p>
                </div>
                <div className="col-sm-5">
                  <p>Start a conversation</p>
                  <p className="h5">
                    <a href="mailto:info@think-plus.gr" className="link--plain" title="Send us a message" target="_blank">info@think-plus.gr</a>
                    &nbsp;|&nbsp;
                    <a href="tel:+302106101478" className="link--plain" title="Give us a call" target="_blank">+30 210 6101 478</a>
                   </p>
                </div>
                <div className="col-sm-2">
                  <p>Join the team</p>
                  <p className="h5">
                    <a href="https://thinkplus.join.com/" className="link--plain" title="Find a job position in Think Plus" target="_blank" rel="noopener noreferrer nofollow">We're Hiring!</a>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="contactus__map">
            <div className="container medium">
              <div className="row">
                <div className="col">
                  <div className="map">
                    <Map />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p className="h5">If you’re interested in building your brand or wish to use any of our other services, please fill out the form below to schedule an appointment and we will contact you shortly.</p>
                </div>
              </div>
            </div>
          </section>
          <ContactForm />
        </article>
      </Layout>
    </>;
}

export default pageContactus
